import $ from 'jquery';
import VideoPlayer from './shared/videoPlayer';
import FileDownloader from './shared/fileDownloader';

export default class PageManaer {

    constructor(page) {

        this.$page = page ? page : $('.body >div');

        this.init();
    }

    init() {

        if (this.$page.find('.app-video').length) {
            if (navigator.appVersion.indexOf("Mac") !== -1 || navigator.userAgent.indexOf('MSIE') > 0 || navigator.userAgent.indexOf('Trident/') > 0) {
                let $videoTag = $('video[cmg-app-video]');
                let $videoTagContainer = $videoTag.parent();
                $videoTag.remove();
                $videoTagContainer.append('<img src="https://res.cloudinary.com/dvbdysuf5/image/upload/v1581021662/CMG_Web_Resources/Home_Images/Mobile_Features_wo_Search.gif" alt="Search" />');
            }
        }

        if (this.$page.is('#home_page')) {
            new VideoPlayer();     
        }
        if (this.$page.is('#testimonial_page')) {
            new VideoPlayer();
        }
        if (this.$page.is('#hiw_page')) {
            new VideoPlayer();
        }
        if (this.$page.is('#webinar_ll_page')) {
            new VideoPlayer();
        }
    }

   
}