import $ from 'jquery';

export default class SiteNavigator {

    constructor(){

        this.selectors = {
            mobile: '.site-navigator-mobile',
            desktop: '.site-navigator',
            page: 'body',
            openButton: '[data-role="open-button"]',
            closeButton: '[data-role="close-button"]',
            navLink: '.menu-link'
        };

        this.init();
    }

    init() {

        this.$page = $(this.selectors.page);

        this.$mobNavigator = {
            navigator: this.$page.find(this.selectors.mobile)
        };

        this.$mobNavigator.openButton = this.$mobNavigator.navigator.find(this.selectors.openButton);
        this.$mobNavigator.closeButton = this.$mobNavigator.navigator.find(this.selectors.closeButton);
        this.$mobNavigator.menuLinks = this.$mobNavigator.navigator.find(this.selectors.navLink);
        
        this.$deskNavigator = {
            navigator: this.$page.find(this.selectors.desktop)
        };

        this.bind();
    }

    bind() {
        
        this.$mobNavigator.openButton.on({
            'click': (e) => {
                this.$mobNavigator.navigator.addClass('show');
                this.$page.addClass('blured');
            }
        });

        this.$mobNavigator.closeButton.on({
            'click': (e) => {
                this.$mobNavigator.navigator.removeClass('show');
                this.$page.removeClass('blured');
                //this.$mobNavigator.menuLinks.removeClass('active');
            }
        });

        this.$mobNavigator.menuLinks.on({
            'click': (e) => {
                e.stopPropagation();

                var $activatedLink = $(e.currentTarget);
                if (!$activatedLink.children('.menu-dropdown').length)
                    return;

                var $parentMenuLink = $activatedLink.parents('.expanded:first');
                if ($parentMenuLink.length) {

                    var $menuLinks = $parentMenuLink.find(this.selectors.navLink);
                    if ($activatedLink.hasClass('expanded')) {
                        $menuLinks.removeClass('expanded');
                        $activatedLink.removeClass('expanded');
                    }
                    else {
                        $menuLinks.not($activatedLink).removeClass('expanded');
                        $activatedLink.addClass('expanded');
                    }
                }
                else {
                    if ($activatedLink.hasClass('expanded')) {
                        this.$mobNavigator.menuLinks.removeClass('expanded');
                    }
                    else {
                        this.$mobNavigator.menuLinks.not($activatedLink).removeClass('expanded');
                        $activatedLink.addClass('expanded');
                    }
                }
            }
        });

        $(window).on('scroll', (e) => {
            this.toggleBackground();
        });
    }

    toggleBackground() {
        if (window.pageYOffset > 100) {
            this.$deskNavigator.navigator.addClass('bg-white');
        }
        else if (window.pageYOffset <= 100) {
            this.$deskNavigator.navigator.removeClass('bg-white');
        }
    }
}