//--------- NPM
import 'bootstrap/dist/css/bootstrap.css';
import 'magnific-popup/dist/magnific-popup.css';

//--------- SITE STYLES
import '../scss/home/_index.scss';
import '../scss/partners/_index.scss';
import '../scss/borrowers/_index.scss';
import '../scss/testimonials/_index.scss';
import '../scss/webinars/_index.scss';
import '../scss/shared/_index.scss';

//--------- JS NPM
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';

import './testimonial';
import './aiowidget';
import $ from 'jquery';
import PageManager from './pageManager';
import SiteNavigator from './siteNavigator';

$(function () {
    new SiteNavigator();
    new PageManager();
   
    //$('h1').each(function () {
    //    if ($(this).hasClass('noapply')) { }
    //    else { 
    //    var w = this.textContent.split(" ");
    //    if (w.length > 1) {
    //        w[w.length - 2] += "&nbsp;" + w[w.length - 1];
    //        w.pop();
    //        this.innerHTML = (w.join(" "));
    //    }
    //}
    //});
    //$('h2').each(function () {
    //    var w = this.textContent.split(" ");
    //    if (w.length > 1) {
    //        w[w.length - 2] += "&nbsp;" + w[w.length - 1];
    //        w.pop();
    //        this.innerHTML = (w.join(" "));
    //    }
    //});
    //$('p').each(function () {
    //    var w = this.textContent.split(" ");
    //    if (w.length > 1) {
    //        w[w.length - 2] += "&nbsp;" + w[w.length - 1];
    //        w.pop();
    //        this.innerHTML = (w.join(" "));
    //    }
    //});
    //$('.list-items ul li span').each(function () {
    //    var w = this.textContent.split(" ");
    //    if (w.length > 1) {
    //        w[w.length - 2] += "&nbsp;" + w[w.length - 1];
    //        w.pop();
    //        this.innerHTML = (w.join(" "));
    //    }
    //});
});
