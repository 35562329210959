import $ from 'jquery';

export default class FileDownloader {

    constructor() {

        this.selectors = {            
            file: 'data-file',
            iframe: '[file-downloader]'
        };

        this.init();
    }

    init() {

        this.$body = $('body');

        this.$iframe = this.$body.find(this.selectors.iframe);
        if (!this.$iframe.length) {
            this.$body.append('<iframe file-downloader class="d-none"></iframe>');
            this.$iframe = this.$body.find(this.selectors.iframe);
        }

        this.$files = this.$body.find(`[${this.selectors.file}]`);

        this.bind();
    }

    bind() {

        this.$files.on('click', (e) => {
            var url = e.currentTarget.getAttribute(this.selectors.file);
            this.$iframe.attr('src', url);
        });
    }
}