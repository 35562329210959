import $ from 'jquery';
import "magnific-popup";
import '../../scss/shared/video-player.scss';

export default class VideoPlayer {

    //   Animation effects
    //--------------------
    // * Zoom - in (added)
    // * Newspaper
    // * Horizontal move
    // * Move from top
    // * 3d unfold
    // * Zoom - out

    constructor() {        

        this.selectors = {
            video: '[data-role="video-player"]',
            thumbnail: '[data-role="thumbnail"]',
            playButton: '[data-role="play-button"]'
        };

        this.pageVideos = new Array();
                
        this.init();
    }

    init() {

        let $videosContainers = $(this.selectors.video);
        if ($videosContainers.length === 0)
            return;

        $videosContainers.each((i, vp) => {
            let $vp = $(vp);
            let $thumbnail = $vp.find(this.selectors.thumbnail);
            let $playButton = $vp.find(this.selectors.playButton);

            if ($thumbnail.length) {

                let imgSrc = $thumbnail.attr('data-src');
                if (!imgSrc) {
                    //TODO: add support for YouTube 
                    let videoUrl = $playButton.attr('href');
                    $.get({
                        url: 'https://vimeo.com/api/oembed.json?url=' + videoUrl
                    }).done(function (data) {
                        $thumbnail.css('background-image', `url(${data.thumbnail_url})`);
                    }).fail(function () {
                        console.log('Unable to retrive video thambnail (vimeo)');
                        //TODO: render default video image
                    });
                }
                else
                    $thumbnail.css('background-image', `url(${imgSrc})`);
            }
                       
            this.pageVideos.push({                
                thumbnail: $thumbnail,
                playButton: $playButton,
                animationEffect: $vp.attr('data-effect')
            });
        });
      
        this.bindEvents();
    }

    bindEvents() {

        this.pageVideos.forEach(el => {

            el.playButton.on('click', (e) => {

                e.preventDefault();
                
                $.magnificPopup.open({
                    items: {
                        src: '',
                        type: 'iframe'
                    },
                    iframe: {
                        markup: `<div class="mfp-iframe-scaler">'
                                    <div class="mfp-close"></div>
                                    <iframe title="video" class="mfp-iframe" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                                </div>`
                    },
                    mainClass: el.animationEffect,
                    removalDelay: 500,
                    callbacks: {
                        beforeOpen: function () {
                            this.contentContainer.addClass('mfp-with-anim');
                        },
                        open: function () {
                            var videoSrc = el.playButton.attr('href');
                            if (videoSrc.indexOf('autoplay') === -1)
                                this.contentContainer.find('iframe').attr('src', videoSrc + "?autoplay=1");
                        },
                        close: function () {
                            this.contentContainer.find('iframe').attr('src', null);
                        }
                    },
                    midClick: true
                });
                
            });
        });


        //$(window).on('resize', () => {

        //    this.pageVideos.forEach(el => {

        //        el.thumbnail.height(el.thumbnail.width() * 9 / 16);

        //    });
        //});
    }
}